import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageCarousel from "../components/PageCarousel"
import { useStaticQuery, graphql } from "gatsby"

export default function WaterBlasters() {
  const imageData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "images/water-blasters" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>Water blaster repairs | Service Center</title>
        <meta
          name="description"
          content="We repair water blasters quickly and affordably. We stock a large range of parts and accessories to get your water blaster running smoothly in no time."
        />
      </Helmet>

      <Layout title="Water Blasters">
        <section className="overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div>
                  <p>
                    We repair water blasters quickly and affordably. We also
                    stock a range of parts and accessories to get your water
                    blaster back up and running in no time.
                  </p>
                  <p>
                    If your water blaster is no longer operating like it used
                    to, it is probably time for a service. Your water blaster
                    service might involve:
                  </p>
                  <ul>
                    <li>checking the pump and engine oil</li>
                    <li>checking fittings and spark plugs</li>
                    <li>cleaning the air and water filters</li>
                    <li>replacing the pump seals</li>
                  </ul>
                  <p>
                    If you need an upgrade, we have a large range of new water
                    blasters available. To find out more speak to our team of
                    experts in the Mitre10 Mega Masterton store.
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <PageCarousel imageData={imageData} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
